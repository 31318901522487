import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp, apiGetCountry, apiGenerateRegisterOtp, apiResendRegisterOtp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { toast, Notification } from 'components/ui'
import { setLang } from 'store/locale/localeSlice'
import { useTranslation } from 'react-i18next'

function useAuth() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    const curLang = useSelector(state => state.locale);
    
    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if(resp.data.responseCode === 200) {
                    if (resp.data.data) {
                        dispatch(
                            setUser(
                                {
                                    username: resp.data.data.username,
                                    group_id: resp.data.data.group_id,
                                    group_level: resp.data.data.user_group_level,
                                    country_id: resp.data.data.country_id,
                                    cus_id: resp.data.data.cus_id,
                                    country_code: resp.data.data.country_code,
                                    timezone: resp.data.data.timezone,
                                    currency: resp.data.data.currency
                                }
                            )
                        )
                        let defaultLang = resp.data.data.language_code.replace("-", "_").toString().toLowerCase() || "en";
                        let defaultLangId = resp.data.data.language_id;
                        if(curLang.currentLang !== defaultLang && curLang.currentLangId !== defaultLangId) {
                            dispatch(setLang({'lang': curLang.currentLang, 'langId': curLang.currentLangId}));
                        }
                        else {
                            dispatch(setLang({'lang': defaultLang, 'langId': defaultLangId}));
                        }
                    }
                    toast.push(
                        <Notification type="success" duration={2000} title={t('global.success')}>
                            {t('auth.login-success')}
                        </Notification>,
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }
                else {
                    return {
                        status: 'failed',
                        message: resp.data.message
                    }
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.responseCode === 200) {
                    // change to store user data response from api if api return user data in future
                    dispatch(setUser(initialState));
                    return {
                        status: 'success',
                        message: resp.data.message,
                    }
                }else{
                    return {
                        status: 'failed',
                        message: resp.data.message
                    }
                }
               
            }
        } catch (errors) {

            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const getCountry = async (values) => {
        try {
            const resp = await apiGetCountry(values)
            if (resp.data) {
                if (resp.data.responseCode === 200) {
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // navigate(
                    //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    // )
                    return {
                        status: 'success',
                        data: resp.data.country,
                    }
                }else{
                    return {
                        status: 'failed',
                        message: resp.data.message
                    }
                }
               
            }
        } catch (errors) {

            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const generateRegisterOTP = async (values) => {
        try {
            const resp = await apiGenerateRegisterOtp(values)
            
            if (resp.data) {
                if (resp.data.responseCode === 200) {
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // navigate(
                    //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    // )
                    return {
                        status: 'success',
                        data: resp.data.country,
                    }
                }else{
                    return {
                        status: 'failed',
                        message: resp.data.message
                    }
                }
               
            }
        } catch (errors) {

            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const resendRegisterOTP = async (values) => {
        try {
            const resp = await apiResendRegisterOtp(values)
            
            if (resp.data) {
                if (resp.data.responseCode === 200) {
                    // const redirectUrl = query.get(REDIRECT_URL_KEY)
                    // navigate(
                    //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    // )
                    return {
                        status: 'success',
                        data: resp.data.country,
                    }
                }else{
                    return {
                        status: 'failed',
                        message: resp.data.message
                    }
                }
               
            }
        } catch (errors) {

            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        dispatch(setLang({'lang':'en', 'langId': 1}))
        navigate('/home')
    }

    const signOut = async () => {
        try {    
            const resp = await apiSignOut({ access_token: token });
    
            if (resp.data && resp.data.responseCode === 200) {
                handleSignOut();
            } else {
                console.error("Logout failed:", resp.data.message);
            }
        } catch (errors) {
            console.error("Error during logout:", errors?.response?.data?.message || errors.toString());
        }
    };

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        getCountry,
        generateRegisterOTP,
        resendRegisterOTP
    }
}

export default useAuth
